import React from 'react';
import PropTypes from 'prop-types';
import CustomSlider from '../../Common/CustomSlider/CustomSlider';
import PreviewCompatibleImage from '../../Common/PreviewCompatibleImage';

//TODO how to handle that all images should be same size for slider not to jump?
const AccessThousands = ({ title, description, slider, buttonLink }) => (
  <section className="row align-items-center py-5">
    <div className="col col-12 col-md-5 order-1 order-md-0">
      <h3 className="text-primary mb-3">{title}</h3>
      <p className="mb-4">{description}</p>
      {buttonLink && (
        <a href={buttonLink.path} className="mt-4 btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">
          {buttonLink.label}
        </a>
      )}
    </div>
    {slider &&
      <div className="col col-12 col-md-7 mb-4 mb-md-0">
        <CustomSlider>
          {slider.map((slide, i) => (
            <div key={i}>
              {slide && slide.imageContainer && (
                <PreviewCompatibleImage
                  imageInfo={{
                    image: slide.imageContainer.src,
                    alt: slide.imageContainer.alt,
                  }}
                />
              )}
            </div>
          ))}
        </CustomSlider>
      </div>
    }
  </section>
);

AccessThousands.propTypes = {
  title: PropTypes.string,
  slider: PropTypes.array,
  description: PropTypes.string,
  buttonLink: PropTypes.object,
};

export default AccessThousands;
